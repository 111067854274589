// Ouverture du menu mobile
$('.th-menu').click(function () {
    $('.th-menu').toggleClass('open');
    $('#header').toggleClass('menu-open');

    $('.search-wrapper').toggleClass('search-open');
});
$('.shadow-menu').click(function(){
    $('.th-menu').removeClass('open');
    $('#header').removeClass('menu-open');
    $('.search-wrapper').toggleClass('search-open');
});

$('#header .menu-principal a').on('click',function (eventObject) {
    eventObject.preventDefault();

    var current_url = document.location.href;
    var target_url = $(this).attr('href');
    var hash = target_url.split('#');

    if(hash.length > 1) {
        if(hash[0] == current_url) {
            $('html, body').animate({
                scrollTop: $( '#' + hash[1] ).offset().top
            },'slow');

            $('.th-menu').toggleClass('open');
            $('#header').toggleClass('menu-open');
        } else {
            document.location.href = target_url;
        }
    } else {
        document.location.href = target_url;
    }

})



// Dropdown selecteur de langues
$('.currentLang > a').on('focus', function () {
    $('.is-focus-lang').removeClass('is-focus-lang');
    $(this).parents().next().addClass('is-focus-lang');
});

$('#input-search-algolia').attr('name',(new Date()).getTime()+'-search');

setTimeout(function(){
    // init de algolia
    thuriaSearch.init(
        thConfig.algolia.id,
        thConfig.algolia.key,
        ['wp_posts_page'],
        ['wp_posts_apidae'],
        $('#input-search-algolia').get(0),
        $('.search-results','#overlay-search').get(0),
        $('#res-page','#overlay-search').get(0),
        $('.bloc-relation .cards-sit','#overlay-search')
    )
},1000);





// Ouverture du menu mobile
$('li.has-sub-menu > a, li.has-sub-menu > span').on('click', function(){

    //On ferme tous les autres éléments si on clique sur un nouveau
    if(!$(this).hasClass('opened')){
        //Pour partout
        $('.wrapper-nav ul.sub-menu').removeClass('open');
        $('.wrapper-nav .has-sub-menu a').removeClass('opened');
        //Pour le mobile
        $('.wrapper-nav .has-sub-menu').removeClass('sub-open');
    }

    //On active (ou désactive) pour l'élément cliqué
    $(this).parent().find('ul.sub-menu').toggleClass('open');
    $(this).parent().toggleClass('sub-open');
    $(this).toggleClass('opened');
});