// th_maps.addThemes('default', thConfig.map.defaultThemes);

//
th_maps.onLoad(function () {
    th_maps.addMarkerIcon('default', {
        iconUrl: '' + document.location.origin + '/wp-content/themes/aratgp/assets/images/ico/POI@2x.png',
        shadowUrl: null,
        iconSize: [50, 57]
    });
    th_maps.addMarkerIcon('active', {
        iconUrl: '' + document.location.origin + '/wp-content/themes/aratgp/assets/images/ico/POI-active@2x.png',
        shadowUrl: null,
        iconSize: [50, 57]
    });

});


// PAGE LISTING SIT
function callbackMapPageListingSIT(macarte) {
    macarte.zoomControl.setPosition('topleft');
    fm.map.set(macarte);
}



function callbackMapCartePOIs(macarte) {
    parseMapPopup(macarte);
    if ($(window).width() <= 599){
        macarte.dragging.disable();
    }
}


th_maps.init(thConfig.map.init);

function parseMapPopup(macarte){
    var markers = [];

    $(macarte._container).parent().find('.maps-popup').each(function(){

        var latlng = {lat:$(this).attr('data-lat')*1,lng:$(this).attr('data-lng')*1};
        var marker = th_maps.createMarker(macarte,latlng,'default');

        marker.on('popupopen',function(popup) {
            lazyLoadInstance = new LazyLoad({
                elements_selector: ".lazy"
            });
        })

        // th_maps.createInfoWindow($(this).html(),marker,275);
        th_maps.createInfoWindow($(this).prop('outerHTML'),marker,210);
        markers.push(marker);
    });

    var group = new L.featureGroup(markers);
    macarte.fitBounds(group.getBounds());

    return markers;
}


function callbackMiniMapCards(macarte) {
    if (document.body.clientWidth <= 1024) {
        var mobileSliderManager = new mapSliderMarkerMobile(macarte);
        var mobileSliderManager = new bloc_relation_mapWithSlick(macarte);
        // mobileSliderManager.setIndexDataName('id');
        // mobileSliderManager.init($('.bloc-relation'));
        mobileSliderManager.init();
    } else {
        var mobileSliderManager = new bloc_relation_mapWithSlick(macarte);
        mobileSliderManager.init();
    }
}
