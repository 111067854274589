var ThPikadayManager = {

    displayFormat: {
        fr: 'DD/MM/YYYY',
        en: 'YYYY-MM-DD'
    },

    submissionFormat: 'YYYY-MM-DD',
    currentDisplayFormat: '',
    addplaceholderlike: true,


    init: function() {

        if(thGlobalDefaults.currentlanguage == 'fr') {
            var i18n = {
                previousMonth: 'Mois précédent',
                nextMonth: 'Mois prochain',
                months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', "Octobre", "Novembre", "Décembre"],
                weekdays: ['dimanche', " lundi ", " mardi ", " mercredi ", " jeudi ", " vendredi ", " samedi "],
                weekdaysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
            };
        }

        ThPikadayManager.currentDisplayFormat = ThPikadayManager.displayFormat[thGlobalDefaults.currentlanguage];

        $('[pikaday-from]').each(function (index) {

            var id_couple_pikaday = $(this).attr('pikaday-from');

            var $from_elem = $(this);
            var from_elem_val = $from_elem.val();
            var from_placeholder = '<span class="placeholder-like from">'+$from_elem.attr('placeholder')+'</span>';

            var $to_elem = $('[pikaday-to="'+id_couple_pikaday+'"]');
            var to_elem_val = $to_elem.val();
            var to_placeholder = '<span class="placeholder-like to">'+$to_elem.attr('placeholder')+'</span>';

            // on passe le champs en readonly pour désactiver le datepikernatif
            $from_elem.attr('readonly','readonly');
            $to_elem.attr('readonly','readonly');

            // ajout du faux placeholder
            if(ThPikadayManager.addplaceholderlike) {
                if(!from_elem_val) {
                    $from_elem.after(from_placeholder);
                }
                if(!to_elem_val) {
                    $to_elem.after(to_placeholder);
                }
            }

            //ajout de la classe pour avoir la couleur
            if(from_elem_val) {
                $from_elem.addClass('final-color');
            }
            if(to_elem_val) {
                $to_elem.addClass('final-color');
            }

            var config_from = {
                field: $from_elem[0],
                format: ThPikadayManager.submissionFormat,
                minDate: new Date(),
                firstDay:1,
                onOpen: function () {
                    $('.placeholder-like.from').hide();
                },
                onClose: function () {
                    if(!PikFrom.toString()) {
                        $('.placeholder-like.from').show();
                    }
                },
            };

            var config_to = {
                field: $to_elem[0],
                format: ThPikadayManager.submissionFormat,
                minDate: new Date(),
                firstDay:1,
                onOpen: function () {
                    $('.placeholder-like.to').hide();
                },
                onClose: function () {
                    if(!PikTo.toString()) {
                        $('.placeholder-like.to').show();
                    }
                },
            };

            if(thGlobalDefaults.currentlanguage == 'fr') {
                config_from.i18n = i18n;
                config_to.i18n = i18n;
            }

            if(from_elem_val) {
                config_from.defaultDate = new Date(from_elem_val);
            }

            if(to_elem_val) {
                config_to.defaultDate = new Date(to_elem_val);
            }

            var PikFrom = new Pikaday(config_from);

            if (PikFrom.getDate()) {
                config_to.minDate = PikFrom.getDate();
            }

            var PikTo = new Pikaday(config_to);

            //gestion du fait que la date de fin ne puisse être inférieure à la date de début
            $from_elem.on('change', function () {
                $from_elem.addClass('final-color');

                // on le détruit pour éviter trop de récursions avec la méthode setDate
                PikTo.destroy();

                config_to.minDate = PikFrom.getDate();
                config_to.defaultDate = PikFrom.getDate();
                PikTo = new Pikaday(config_to);

            });
            $to_elem.on('change', function () {
                $to_elem.addClass('final-color');
            });


        })
    },




}

// Quand on enlève le focus, si rien n'est écrit dans le input on ferme le volet
$('[type="date"]').focusout(function () {
    if ($(this).val().length < 1) {
        $(this).removeClass('final-color');
    }
});

// A chaque focus
$('[type="date"]').focus(function(){
    $(this).addClass('final-color');
});


//méthode sans changer le type d'input => placeholder géré en css ainsi que la couleur (transparent du coup) du jj/mm/aaaa qui était problématique à la base
if(!isTouchDevice()) {
    ThPikadayManager.init();
}else{

    $('input[type="date"]').each(function(){
        var PH = $(this).attr('placeholder');

        $(this).parent().attr('data-placeholder', PH);
        $(this).parent().addClass('date-holder');

        $(this).on('change', function () {
            if($(this).length > 0){
                $(this).parent().addClass('no-holder');
            }
        });
    });


}



