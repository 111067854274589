var fm = {

    firstinitpagination: true,

    _map: {
        map: null,
        isMobile: false,
        cluster: null,
        markers: {},
        popins: {},
        markerIcon: 'default',
        markerWidth: 210,

        timeoutUpdate: null,
    },

    _selector: {
        cardsContainer: '.listing-sit-js',
        cardsMobileContainer: '.listing-map-mobile > div',
        paginationContainer: '.pagination',
        form: '#facette',
        scrollable: 'html,body',
        autocomplete: '.autocomplete select',
        outoverlay: '.opensystem-selector',
        nb_results: '.resultats-sit',
        nb_filters: '.filtres-actifs',
        btn_loc: '.btn-loc'
    },

    _ajax: {
        scrollTop: 0,
        scrollSpeed: 250,
        last: 0,
    },

    callbacks: {
        afterUpdateListing: null,
        afterUpdate: null,
        clickMarker: null,
    },

    init: function () {

        fm.listing.update();
        fm.pagination.update();
        fm.map.update();
        fm.form.init();
        //fm.form.aucompleteInit();
        //fm.form.outOfOverlayinit();
        //fm.form.geolocInit();
        fm.ajax.history.init();

    },

    async: function (callback) {
        setTimeout(callback, 0);
    },

    ajax: {

        update: function (url, ignoreHistory) {
            if (!ignoreHistory) {
                fm.ajax.history.add(url);
            }
            fm._ajax.last = new Date().getTime();
            fm.async(fm.ajax.updateActions);
            fm.ajax.get(fm._ajax.last, url);
        },

        updateActions: function () {
            $(fm._selector.cardsContainer).height($(fm._selector.cardsContainer).height()).html('<p>' + $(fm._selector.cardsContainer).data('loading') + '</p>');
            $(fm._selector.scrollable).animate({scrollTop: $('.listing-sit header').offset().top}, fm._ajax.scrollSpeed);
        },

        get: function (queryTime, url) {
            $.get(url, function (responseHTML) {
                if (queryTime == fm._ajax.last) {
                    fm.ajax.updateContent(responseHTML);
                }
            });
        },

        updateContent: function (responseHTML) {
            var listingContent = $(responseHTML).find(fm._selector.cardsContainer).html();
            var paginationContent = $(responseHTML).find(fm._selector.paginationContainer).html();
            var formContent = $(responseHTML).find(fm._selector.form).html();
            var nb_results = $(responseHTML).find(fm._selector.nb_results).html();
            var nb_filters = $(responseHTML).find(fm._selector.nb_filters).text();

            console.log(nb_results);

            $(fm._selector.nb_results).html(nb_results);
            $(fm._selector.nb_filters).text(nb_filters);

            fm.listing.update(listingContent);
            fm.pagination.update(paginationContent);
            fm.map.update();

            $(fm._selector.cardsContainer).removeAttr('style');

            if (fm.callbacks.afterUpdate) {
                fm.callbacks.afterUpdate(responseHTML);
            }

        },

        history: {
            init: function () {
                window.addEventListener('popstate', function () {
                    fm.ajax.update(document.location.href, true);
                });
            },
            add: function (url) {
                if (typeof window.history != "undefined" && window.history.pushState) {
                    window.history.pushState("", "", url);
                } else {
                    document.location.href = url;
                }
            },
        }

    },

    form: {
        init: function () {
            $(fm._selector.form).on('submit', function (e) {
                e.preventDefault();
                e.stopPropagation();
                var query = $(":input", this)
                    .filter(function (index, element) {
                        return $(element).val() != '';
                    })
                    .filter(function (index, element) {
                        return $(element).val() != 'empty';
                    })
                    .serialize();
                var url = this.getAttribute('action');
                fm.ajax.update(url + '?' + query);

            });

            fm.form.update();
        },

        update: function (html) {

            $('input[name="lat"]').val($('input[name="lat"]', html).val());
            $('input[name="lng"]').val($('input[name="lng"]', html).val());
            $('input[name="radius"]').val($('input[name="radius"]', html).val());

            $('input[type="checkbox"], input[type="date"], input[type="text"], input[type="radio"], select, .facette-slider input', fm._selector.form).on('change', function () {
                $(fm._selector.form).submit();
            });
        },
        outOfOverlayinit: function () {
            $('input, select', fm._selector.outoverlay).on('change', function () {
                input_custom_name = $(this).attr('name').split('_');
                if (input_custom_name[0] == 'du' || input_custom_name[0] == 'au') {
                    input_name = input_custom_name[0];
                } else {
                    input_name = $(this).attr('name');
                }
                input_val = $(this).val();
                $('[name="' + input_name + '"]', fm._selector.form).val(input_val);
            })
            $('.cta.submit', fm._selector.outoverlay).on('click', function () {
                $(fm._selector.form).submit();
            });
        },
        aucompleteInit: function () {
            $('.rayon').hide();
            $(fm._selector.autocomplete).selectric('destroy');
            $(fm._selector.autocomplete).selectize({
                plugins: ['remove_button'],
                onFocus: function (value) {
                    if ($('.selectize-input .item').data('value') == 'empty' && ($('.selectize-input .item').text())) {
                        //$('.selectize-input .item').data('value','');
                        //$('.selectize-input .item').text('');
                    }
                },
                onItemAdd: function (value, $item) {
                    var dataObject = {
                        'event': 'event-commune',
                        'category': 'Commune',
                        'action': 'Choix commune',
                        'label': $item.text().trim()
                    };
                    if (typeof dataLayer != 'undefined') {
                        dataLayer.push(dataObject);
                    }

                    $('.rayon').show(500);
                },
                onItemRemove: function (value, $item) {
                    $('.rayon').hide();
                }
            });


        },
        geolocInit: function () {
            $(fm._selector.btn_loc).click(function (e) {
                e.preventDefault();
                e.stopPropagation();
                $self = $(this);
                $self.parent().toggleClass('active');
                var positionDejaprise = false;
                setTimeout(function () {
                    if ($self.parent().hasClass('active')) {
                        console.log('Activation de la geoloc');

                        if (navigator.geolocation) {

                            if (positionDejaprise !== false) {
                                console.log('Geoloc déjà récupéré, affichage');


                                var pos = positionDejaprise;
                                $('input[name="lat"]').val(pos.lat);
                                $('input[name="lng"]').val(pos.lng);
                                markerTargetCurrentGeo.setPosition(pos);
                                markerTargetCurrentGeo.setMap(macarte);
                                macarte.setCenter(pos);

                                $('.page-listing form').submit();
                            } else {
                                console.log('Demande de geoloc ...');
                                navigator.geolocation.getCurrentPosition(handleGeoloc, function (e) {
                                    alert("Votre support ne nous permet pas de trouver votre position. Vérifiez les paramètres de localisation de votre appareil.");
                                });
                            }

                        } else {
                            alert("Votre support ne nous permet pas de trouver votre position. Vérifiez les paramètres de localisation de votre appareil.");
                        }
                    } else {
                        console.log('Désactivation de la geoloc');

                        $('input[name="lat"]').val('');
                        $('input[name="lng"]').val('');
                        markerTargetCurrentGeo.setMap(null);
                        $('.page-listing form').submit();
                    }
                }, 100);
            });
        }

    },

    listing: {

        update: function (html) {
            if (html) {
                $(fm._selector.cardsContainer).html(html);
                $(fm._selector.cardsMobileContainer).html(html);
            }
            if (fm.callbacks.afterUpdateListing) {
                fm.callbacks.afterUpdateListing();
            }
        },
        getCards: function () {
            var cards = {};
            $('.card', fm._selector.cardsContainer).each(function () {
                var lat = this.getAttribute('data-lat') * 1;
                var lng = this.getAttribute('data-lng') * 1;
                if (lat != 0 && lng != 0) {

                    var card = {
                        id: this.getAttribute('data-id'),
                        lat: lat,
                        lng: lng,
                        html: this.outerHTML.replace('data-src', 'src').replace('lazy', ''),
                    };

                    cards[this.getAttribute('data-id')] = card;
                }
            });

            delete cards.null;

            return cards;
        },

    },

    pagination: {
        update: function (html) {
            if (html) {
                $(fm._selector.paginationContainer).html(html);
            }
            if ((typeof html == 'undefined' || html == '') && fm.firstinitpagination === false) {
                $(fm._selector.paginationContainer).html('');
            }
            fm.pagination.parse();
            fm.firstinitpagination = false;
        },
        parse: function () {
            $('a', fm._selector.paginationContainer).on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
                fm.ajax.update(this.getAttribute('href'));
            });
        },
    },

    map: {

        set: function (map, isMobile) {
            fm._map.map = map;
            if (isMobile) {
                fm._map.isMobile = isMobile;
            }
        },

        update: function () {

            var cards = fm.listing.getCards();

            clearTimeout(fm._map.timeoutUpdate);
            if (!fm._map.map) {
                setTimeout(function () {
                    fm.map.update();
                }, 500);
                return false;
            }

            fm.map.hideCluster();

            if (document.body.clientWidth <= 1024) {
                var mobileSliderManager = new mapSliderMarkerMobile(fm._map.map);
                mobileSliderManager.setIndexDataName('id');
                mobileSliderManager.init($('.bloc-relation'));

            } else {
                fm._map.cluster = L.markerClusterGroup({showCoverageOnHover: false});

                $.each(cards, function (k, card) {
                    fm._map.cluster.addLayer(fm.map.getMarker(card));
                });

                fm._map.map.addLayer(fm._map.cluster);
            }

            setTimeout(function () {
                fm._map.map.invalidateSize();

                if (document.body.clientWidth > 1024) {
                    fm._map.map.fitBounds(fm._map.cluster.getBounds(), {padding: [25, 25]});
                }

                if (fm.callbacks.mapUpdate) {
                    fm.callbacks.mapUpdate(fm._map.map);
                }

            }, 250);
        },

        getMarker: function (card) {
            if (!fm._map.markers[card.id]) {
                fm.map.createMarker(card);
            }
            return fm._map.markers[card.id];
        },
        createMarker: function (card) {
            fm._map.markers[card.id] = th_maps.createMarker(null, {lat: card.lat, lng: card.lng}, fm._map.markerIcon);

            fm._map.markers[card.id].on('click', function () {
                if (fm.callbacks.onClickMarker) {
                    fm.callbacks.onClickMarker(card.id, fm._map.markers[card.id], card);
                }
            });

            if (!fm._map.isMobile) {
                card_html = card.html;
                card_html = card_html.replace('src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7', '');
                fm._map.popins[card.id] = th_maps.createInfoWindow(card_html, fm._map.markers[card.id], fm._map.markerWidth);
            }
        },

        hideCluster: function () {
            if (!fm._map.map) {
                return null;
            }

            if (fm._map.cluster) {
                fm._map.map.removeLayer(fm._map.cluster);
            }
        }
    },


};


if ($('#facette').length > 0) {
    fm.init();
}

fm.callbacks.afterUpdateListing = function () {
    var lazyLoadInstance = new LazyLoad({
        elements_selector: ".lazy"
    });
}


$('.openmap').on('click', function () {
    $('.map-wrapper').addClass('open');
    $('#header').addClass('hide-menu');
});

$('.closemap').on('click', function () {
    $('.map-wrapper').removeClass('open');
    $('#header').removeClass('hide-menu');
});