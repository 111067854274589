var th_overlay = {

    selector_overlay: '.overlay:not(.overlay-map)',
    selector_overlay_shadow: '.shadow-overlay',

    callbackOpen: [],
    callbackClose: [],
    hashList: {},

    init: function(){
        th_overlay.parseOverlayShadow($('body'));
        th_overlay.parse($('body'));
        th_overlay.openOnLoad();
    },

    addCallbackOpen: function(callback){
        th_overlay.callbackOpen.push(callback);
    },
    addCallbackClose: function(callback){
        th_overlay.callbackClose.push(callback);
    },

    parseOverlayShadow: function(){
        $(th_overlay.selector_overlay_shadow).on('click',function(e){
            e.preventDefault();
            $(th_overlay.selector_overlay_shadow).removeClass('open');
            $(th_overlay.selector_overlay+'.open').removeClass('open');
        });
    },

    parse: function(el){

        $(th_overlay.selector_overlay).each(function(){

            var overlayId = this.id;
            var targetOpen = $('a[href="#'+overlayId+'"]:not(.parsed-overlay):not(.close), [data-overlay-open="'+overlayId+'"]:not(.parsed-overlay)');

            if ($(this).data("hash") !== undefined){
                th_overlay.hashList[$(this).attr("data-hash")] = overlayId;
            }

            targetOpen.addClass('parsed-overlay')
                .on('click',function(e){
                    e.preventDefault();
                    var idOverlay = $(this).attr('href');
                    if(!idOverlay){
                        idOverlay = $(this).attr('data-overlay-open');
                    }else{
                        idOverlay = idOverlay.replace('#','');
                    }
                    th_overlay.open(idOverlay);
                });

            $('a[href="#'+overlayId+'"].close:not(.parsed-overlay), [data-overlay-close="'+overlayId+'"]:not(.parsed-overlay)')
                .addClass('parsed-overlay')
                .on('click',function(e){

                    var idOverlay = $(this).attr('href');
                    if(!idOverlay){
                        idOverlay = $(this).attr('data-overlay-close');
                    }else{
                        idOverlay = idOverlay.replace('#','');
                    }

                    if(!idOverlay){
                        idOverlay = null;
                    }
                    e.preventDefault();
                    th_overlay.close(idOverlay);
                });
        });
    },

    openOnLoad: function(){

        var current_hash = (window.location.hash).split('#');
        var founded_overlay = false;
        if (current_hash) {
            current_hash.forEach(function (element) {
                if (typeof th_overlay.hashList[element] !== 'undefined' && !founded_overlay){
                    th_overlay.open(th_overlay.hashList[element]);
                    founded_overlay = true;
                }
            })
        }
    },

    open: function(overlayId,openShadow,doCallback){
        if(openShadow !== false){
            openShadow = true;
        }
        if(doCallback !== false){
            doCallback = true;
        }


        $(th_overlay.selector_overlay+".open").each(function (e) {
            th_overlay.close($(this).attr('id'),false,true)
        })

        $('#'+overlayId).addClass('open');
        $('body').addClass('overlay-open');

        if ($('#'+overlayId+'').data('hash') !== undefined){
            window.location.hash = $('#'+overlayId+'').data('hash');
        }

        if(openShadow == true){
            $(th_overlay.selector_overlay_shadow).addClass('open');
        }

        if(doCallback == true){
            $.each(th_overlay.callbackOpen,function(k,callback){
                callback(overlayId);
            });
        }
    },

    close: function(overlayId,closeShadow,doCallback){

        if(closeShadow !== false){
            closeShadow = true;
        }
        if(doCallback !== false){
            doCallback = true;
        }

        window.location.hash = "";
        history.replaceState(null, null, ' ');


        if(overlayId){
            $('#'+overlayId).removeClass('open');
            if($(th_overlay.selector_overlay+'.open').length == 0 && closeShadow){
                $(th_overlay.selector_overlay_shadow).removeClass('open');
            }
        }else{
            $(th_overlay.selector_overlay+'.open').removeClass('open');
            if(closeShadow){
                $(th_overlay.selector_overlay_shadow).removeClass('open');
            }
        }

        if(doCallback){
            $.each(th_overlay.callbackClose,function(k,callback){
                callback(overlayId);
            });
        }
        $('body').removeClass('overlay-open');

    }

};

th_overlay.init();