var thuriaSearch = {

    scriptLoaded: false,

    client: null,
    apidaeIndex: null,
    pagesIndex: null,
    basic_filters: '',

    input: null,
    searchContainer: null,
    searchContainerPost: null,
    searchContainerApidae: null,
    scrollers: {},
    strates: {},

    lastSearch: 0,

    lastText: null,
    lastResultNbPage: 0,
    lastHitsCountPerIndex: {},
    lastPage: 0,

    lockNextPage: false,
    noresultPhrase: null,

    timeoutInputLimit:null,

    init: function(id,key,pagesIndex,apidaeIndex,input,searchContainer,searchContainerPost,searchContainerApidae){

        if(typeof algoliasearch == 'undefined'){
            setTimeout(function(){
                thuriaSearch.init(id,key,pagesIndex,apidaeIndex,input,searchContainer,searchContainerPost,searchContainerApidae);
            },500);
            return false;
        }

        thuriaSearch.basic_filters = "taxonomies.language:" + $('html').data('fulllang');
        thuriaSearch.strates = thGlobalDefaults.algolia.strates;
        thuriaSearch.client = algoliasearch(id, key);
        thuriaSearch.input = input;
        thuriaSearch.pagesIndex = pagesIndex;
        thuriaSearch.apidaeIndex = apidaeIndex;
        thuriaSearch.searchContainer = searchContainer;
        thuriaSearch.searchContainerPost = searchContainerPost;
        thuriaSearch.searchContainerApidae = searchContainerApidae;
        thuriaSearch.noresultPhrase = thuriaSearch.searchContainer.getAttribute('data-noresult');
        thuriaSearch.initInputEvents();
        thuriaSearch.initScrollers();

        return true;
    },

    initInputEvents: function(){

        thuriaSearch.input.addEventListener('keydown',function(){
            setTimeout(function(){
                thuriaSearch.search(thuriaSearch.input.value);
            },25);
        });
    },

    initScrollers: function(){

        if(typeof scrollersLazy != 'undefined') {
            thuriaSearch.scrollers = scrollersLazy;
        } else {
            setTimeout(function(){
                thuriaSearch.scrollers = scrollersLazy;
            },400);
        }

        $.each(thuriaSearch.scrollers,function (id, scrollerObj) {

            if(scrollerObj.scroller) {

                // Cas où Iscroll est actif (>1024 px)
                scrollerObj.scroller.on('scroll', function () {
                    if (this.x != 0) {
                        current_scroll = -this.x;
                    } else {
                        current_scroll = this.x;
                    }

                    total_width = $('[data-iscroll] > div ', '#' + id).width();
                    window_width = $('[data-iscroll]', '#' + id).width();
                    percent = current_scroll / (total_width - window_width);

                    if (percent > 0.8 && scrollerObj.loading == false) {
                        scrollerObj.loading = true;
                        setTimeout(function () {

                            thuriaSearch.nextPage(id);
                        }, 200);
                    }
                })
            } else {
                // Cas du scroll natif

                $('.relation','#'+id).on('scroll',function (eventObject) {

                    current_scroll = $(this).scrollLeft();

                    nb_items = $('[data-iscroll] .card ', '#' + id).length;
                    item_width = $('[data-iscroll] .card ', '#' + id).width();
                    total_width = nb_items * item_width;
                    window_width = $('[data-iscroll]', '#' + id).width();
                    percent = current_scroll / total_width;

                    if (percent > 0.8 && thuriaSearch.scrollers[id].loading == false) {

                        thuriaSearch.scrollers[id].loading = true;

                        setTimeout(function () {
                            thuriaSearch.nextPage(id);
                        }, 200);
                    }
                });
            }
        })

    },

    refreshScrollers:function (IscrollObj,id_container) {

        if(IscrollObj.scroller) {
            setTimeout(function () {

                var $slider = $('[data-iscroll]', '#' + id_container);
                var $sliderItems = $('>div', $slider);
                var $items = $('>div>*:not(.iScrollIndicator)', $slider);
                var itemWidth = Math.floor($items.first().outerWidth(true));

                var countItems = $items.length;
                var contentWidth = countItems * (itemWidth);
                $sliderItems.width(contentWidth);

                IscrollObj.scroller.refresh();
            }, 200);
            setTimeout(function () {
                $( window ).resize();
            }, 500);
        }
    },


    search: function(text){
        thuriaSearch.lastText = text;
        thuriaSearch.lastSearch++;
        var queryId = thuriaSearch.lastSearch;

        if(thuriaSearch.lastText.length > 2) {
            var q = thuriaSearch.generateQueries({
                query: thuriaSearch.lastText,
                params: {page: 0, hitsPerPage: 12, filters: thuriaSearch.basic_filters}
            },false, thuriaSearch.pagesIndex, false);

            thuriaSearch.client.search(q, function (err, result) {
                if (!err) {
                    var results = result.results;
                    thuriaSearch.handleSearchResultPost(results, queryId);
                }
            });

            var q = thuriaSearch.generateQueries({
                query: thuriaSearch.lastText,
                params: {page: 0, hitsPerPage: 12, filters: thuriaSearch.basic_filters}
            }, false , thuriaSearch.apidaeIndex, false);

            thuriaSearch.client.search(q, function (err, result) {
                if (!err) {
                    var results = result.results;
                    thuriaSearch.handleSearchResultApidae(results, queryId, false);
                }
            });


            if($('.noresult:not(.bloc-relation)').length == 0) {
                $('#overlay-search .manual-content').addClass('noresult');
            } else {
                $('#overlay-search .manual-content').removeClass('noresult');
            }

        } else {
            $('#overlay-search .manual-content').removeClass('noresult');
            thuriaSearch.handleSearchResultPost({hits:[]},queryId);
            thuriaSearch.handleSearchResultApidae({hits:[]},queryId, false);
        }

    },

    /***
     *
     * @param query
     * @param checkLastResult
     * @param indexs : précise le tableau d'indexs à parcourir
     * @param strate bool|string : si string, genere uniquement la query de la strate demandée
     * @returns {Array}
     */
    generateQueries: function(query, checkLastResult, indexs, asked_strate){


        var finalQueries = [];

        $.each(indexs,function(k,indexName){

            if(indexName == 'wp_posts_apidae') {

                // strates Apidae
                $.each(thuriaSearch.strates,function (i, strate) {

                    if(asked_strate == false || asked_strate == strate.id) {

                        var add_to_query = '';

                        //obliger de faire ce truc chelou pour cloner l'objet d'origine
                        var query_strate = JSON.parse(JSON.stringify(query));

                        if(!asked_strate) {
                            //console.log(strate.title + ' -------------------- :');
                        }

                        nb_selections = strate.selections.length;

                        if(nb_selections > 0) {
                            add_to_query = ' AND (';

                            $.each(strate.selections,function (j, selection) {
                                if(j > 0 && j < nb_selections) {
                                    add_to_query += ' OR '
                                }
                                add_to_query += 'taxonomies.apidae_selections:'+selection;
                            })

                            add_to_query += ') ';


                            query_strate.params.filters += add_to_query;

                            if(!asked_strate) {
                                //console.log("query_strate : " + query_strate.params.filters);
                            }

                            // pages et autres
                            var isOk = false;
                            if (checkLastResult) {
                                if (thuriaSearch.lastHitsCountPerIndex[strate.id] > 0) {
                                    isOk = true;
                                }
                            }

                            if (!checkLastResult || isOk) {
                                var q = $.extend(false, query_strate, {indexName: indexName});
                                finalQueries.push(q);
                            }
                        }
                    }
                })
            } else {
                // pages et autres
                var isOk = false;
                if (checkLastResult) {
                    if (thuriaSearch.lastHitsCountPerIndex[indexName] > 0) {
                        isOk = true;
                    }
                }
                if (!checkLastResult || isOk) {
                    var q = $.extend(false, query, {indexName: indexName});
                    finalQueries.push(q);
                }
            }



        });



        return finalQueries;
    },


    nextPage: function(strateid){

        var queryId = thuriaSearch.lastSearch;

        if(strateid == 'res-page') {
            indexs = thuriaSearch.pagesIndex;
            strate = false;

            console.log('load next page :  res-page : ' + (thuriaSearch.lastPage + 1));

            var q = thuriaSearch.generateQueries({
                query: thuriaSearch.lastText,
                params: {
                    page: thuriaSearch.lastPage + 1,
                    hitsPerPage: 12,
                    filters: thuriaSearch.basic_filters
                    }
                }, true, indexs, strate);


            thuriaSearch.client.search(q, function (err, result) {
                if (!err) {
                    var results = result.results;

                    var results = result.results;
                    var lastPage = null;
                    var lastNbPage = 0;
                    $.each(results,function(k,r){
                        if(r.nbPages > lastNbPage){
                            lastNbPage = r.nbPages;
                            lastPage = r.page;
                        }
                    });
                    thuriaSearch.lastPage = lastPage;
                    thuriaSearch.lastResultNbPage = lastNbPage;

                    if(thuriaSearch.lastResultNbPage < (thuriaSearch.lastPage - 1)) {
                        thuriaSearch.scrollers[strateid].loading = false;
                    } else {
                        thuriaSearch.scrollers[strateid].loading = true;
                    }

                    if(thuriaSearch.lastPage) {
                        thuriaSearch.handleSearchResultPost(results, queryId);
                    }
                }
            });

        } else {

            indexs = thuriaSearch.apidaeIndex;
            strate = strateid;
            strate_num = strate.split('-');
            strate_num = strate_num[1];

            console.log('load next page : ' + strateid + ' page : ' + (thuriaSearch.strates[strate_num].page + 1));

            var q = thuriaSearch.generateQueries({
                query: thuriaSearch.lastText,
                params: {
                    page: thuriaSearch.strates[strate_num].page+1,
                    hitsPerPage: 12,
                    filters: thuriaSearch.basic_filters
                    }
                }, true, indexs, strate);

            thuriaSearch.client.search(q, function (err, result) {
                if (!err) {
                    var results = result.results;

                    if(results[0].page < (results[0].nbPages - 1)) {
                        thuriaSearch.strates[strate_num].page = thuriaSearch.strates[strate_num].page +1;
                        thuriaSearch.scrollers[strateid].loading = false;
                    } else {
                        thuriaSearch.scrollers[strateid].loading = true;
                    }

                    if(results[0].nbPages > 1) {
                        thuriaSearch.handleSearchResultApidae(results, queryId, strate);
                    }
                }
            });
        }

    },

    /**
     *
     * @param result
     * @param queryId
     * @param asked_strate false|string : si string c'est qu'on est dans de la pagination
     */
    handleSearchResultApidae: function(result,queryId, asked_strate){

        if(thuriaSearch.lastSearch == queryId){

            thuriaSearch.searchContainer.scrollTop = 0;

            $.each(thGlobalDefaults.algolia.strates,function (i, strate) {

                var id_container = strate.id;

                if(asked_strate == false || asked_strate == id_container) {

                    use_result = result[i];

                    if(asked_strate) {
                        use_result = result[0];
                    } else {
                        $('#' + id_container + ' .cards-sit').get(0).innerHTML = '';
                        $('#' + id_container + ' .nbresultats').text('');
                        thuriaSearch.scrollers[id_container].loading = false;
                    }

                    if (typeof use_result != 'undefined' && use_result.hits.length > 0) {

                        $('#' + id_container + ' .nbresultats').text(use_result.nbHits);
                        thuriaSearch.lastHitsCountPerIndex[id_container] = use_result.hits.length;

                        var html = '';
                        $.each(use_result.hits, function (k2, hit) {
                            html += hit.cards_html;
                        });

                        $('#' + id_container + ' .cards-sit').get(0).innerHTML += html;

                        if($('#' + id_container).hasClass('noresult')) {
                            $('#' + id_container).removeClass('noresult');
                        }

                    } else {
                        thuriaSearch.lastHitsCountPerIndex[id_container] = 0;

                        // var el = document.createElement('div');
                        // el.innerHTML = '<p>' + thuriaSearch.noresultPhrase + '</p>';
                        // $('#' + id_container + ' .cards-sit').html(el);

                        if(!$('#' + id_container).hasClass('noresult')) {
                            $('#' + id_container).addClass('noresult');
                        }


                        thuriaSearch.scrollers[id_container].page = 0;
                    }

                    thuriaSearch.refreshScrollers(thuriaSearch.scrollers[id_container], id_container);

                }
            });

        }
    },

    handleSearchResultPost: function(result,queryId){
        if(thuriaSearch.lastSearch == queryId){

            var container_posts = $('.cards-edito',thuriaSearch.searchContainerPost).get(0);

            container_posts.innerHTML = '';
            if(result.length > 0){

                var html = '';

                var nb_results = 0;

                $.each(result,function(k,result){
                    $.each(result.hits,function(k2,hit){
                        nb_results ++;
                        html += hit.cards_html;
                    });
                });

                if(nb_results > 0) {
                    container_posts.innerHTML += html;
                    thuriaSearch.scrollers['res-page'].page  = thuriaSearch.scrollers['res-page'].page + 1;

                    if($(thuriaSearch.searchContainerPost).hasClass('noresult')) {
                        $(thuriaSearch.searchContainerPost).removeClass('noresult');
                    }
                } else {
                    if(!$(thuriaSearch.searchContainerPost).hasClass('noresult')) {
                        $(thuriaSearch.searchContainerPost).addClass('noresult');
                    }
                }

            } else {

                // var el = document.createElement('div');
                // el.innerHTML = '<p>'+ thuriaSearch.noresultPhrase +'</p>';
                // $('.cards-edito',thuriaSearch.searchContainerPost).html(el);

                thuriaSearch.scrollers['res-page'].page = 0;

                if(!$(thuriaSearch.searchContainerPost).hasClass('noresult')) {
                    $(thuriaSearch.searchContainerPost).addClass('noresult');
                }
            }
        }
    },
};