$('[data-anchor]').each(function(){
    $(this).on('click',function(e){
        e.preventDefault();
        var anchor = $(this).attr('data-anchor');
        if(anchor == 'scroll')
        {
            $('html, body').animate({scrollTop:($('html,body').scrollTop()+760-55)},'slow');
        }else{
            $('html, body').animate({scrollTop:$($(this).attr('href')).offset().top},'slow');
        }
        return false;
    });
});