
function bloc_relation_mapWithSlick(macarte){

    this.map = macarte;
    this.blocEl = null;
    this.sliderEl = null;
    this.markers = [];
    this.cards = [];
    this.lastOnMarker = null;
    this.markerDefault = 'default'; // nom de l'icone a afficher par défaut
    this.markerCurrent = 'active'; // nom de l'icone au scroll
    this.sliderSelector = '.relation';


    this.init = function(){
        var _self = this;
        this.blocEl = $(this.map ._container).parent().parent();
        this.sliderEl = $(this.sliderSelector,this.blocEl);

        console.log(this.sliderEl.offset());

        $('.card',this.blocEl).each(function(index,el){
            _self.generateMarker(index,el);
        });


        this.boundsMap();

    };

    this.generateMarker = function(index,card){
        var _self = this;

        this.cards[index]= card;
        this.markers[index] = th_maps.createMarker(this.map,{lat:card.getAttribute('data-lat')*1,lng:card.getAttribute('data-lng')*1},this.markerDefault);

        this.markers[index].addEventListener('click',function() {
            _self.onMarker(index,true);
        });

        if(isTouchDevice() && document.body.clientWidth < thConfig.tabletPaysageBreakpoint){
            // version mobile

        }else{
            // version desktop au hover
            $(card).on('mouseover',function(){
                _self.onMarker(index);
            }).on('mouseleave',function(){
                _self.offMarker(index);
            });
        }

    }

    this.boundsMap = function(){
        var latLngs = [ ];
        var _self = this;

        $.each(this.markers,function(i,m){
            latLngs.push(m.getLatLng());

            this.on('click',function(ev) {
                // console.log('click du marker '+i);
                // console.log(m._latlng.lat);

                var currentslider = _self.cards[i].closest('.relation');//récupérer le bon slider
                $(currentslider).slick('slickGoTo', i);

            });
        });
        var markerBounds = L.latLngBounds(latLngs);
        this.map.fitBounds(markerBounds);


        var markers = this.markers;
        $(this.sliderEl).on('afterChange', function(){
            var currentSlide = $('.slick-active',$(this)).attr('data-slick-index');
            // markers[currentSlide];
            console.log(markers[currentSlide]);

            var marker = markers[currentSlide];
            // var card = this.cards[currentSlide];
            $(markers).each(function(){
                this.setIcon(th_maps.markersIcons['default']);
            });
            marker.setIcon(th_maps.markersIcons['active']);

            $('.card',$(this)).removeClass('hover');
            $('.slick-active',$(this)).addClass('hover');

            var zoom = macarte.getZoom();
            if(zoom > 16){ zoom = 16; }
            macarte.flyTo(marker.getLatLng(),zoom);
        });
    };

    this.onMarker = function(i,moveSlick){
        var marker = this.markers[i];
        var card = this.cards[i];


        if (document.body.clientWidth <= 1024) {

            var ol = $(card).offset().left;
            var olWrapper = (document.body.clientWidth / 2);
            var sl = this.sliderEl.scrollLeft();
            var slider = this.sliderEl;

            console.log('card offset left : '+ol,'card width : '+$(card).width(),'ol wrapper : '+olWrapper,'slider scrollleft : '+sl,'calcul : '+(sl+ol-olWrapper));

            slider.animate({scrollLeft:(sl+ol-olWrapper)},300);
        }

        $(card).addClass('hover');

        if( this.lastOnMarker || this.lastOnMarker === 0){
            this.offMarker(this.lastOnMarker);
        }
        this.lastOnMarker = i;
        marker.setIcon(th_maps.markersIcons[this.markerCurrent]);

        var zoom = macarte.getZoom();
        if(marker.__parent){
            if(marker.__parent._zoom){
                zoom = marker.__parent._zoom+1
            }
        }
        if(zoom > 16){ zoom = 16; }
        macarte.flyTo(marker.getLatLng(),zoom);
    }

    this.offMarker = function(i){
        var marker = this.markers[i];
        var card = this.cards[i];

        $(card).removeClass('hover');
        marker.setIcon(th_maps.markersIcons[this.markerDefault]);
    }
}


function mapSliderMarkerMobile(map){

    // custom par site
    this.sliderScrollSelector = '.relation'; // class de l'element en overflow qui scroll les cards
    this.cardsSelector = '.card'; // class d'un item de slider
    this.indexDataName = 'index'; // nom de l'attribut data sans data qui contient un id par marker
    this.markerDefault = 'default'; // nom de l'icone a afficher par défaut
    this.markerCurrent = 'active'; // nom de l'icone au scroll

    // fonctionnelles
    this.timeout  = null;
    this.last = null;
    this.markers = {};
    this.map = map;

    this.setIndexDataName = function(indexName){
        this.indexDataName = indexName;
    };

    /**
     * Initialisation : Ajoute les markers et lance l'écoute du scroll
     *
     * @param elSlider element contenant le slider et les cards
     * @param callback callback possible en cas de changement de slide
     */
    this.init = function(elSlider,callback){

        var _self = this;
        var latLngs = [ ];

        $(this.cardsSelector,elSlider).each(function(){

            var geo = {
                lat:this.getAttribute('data-lat')*1,
                lng:this.getAttribute('data-lng')*1,
            };
            var index = this.getAttribute('data-'+_self.indexDataName);

            _self.markers[index] = th_maps.createMarker(_self.map,geo,_self.markerDefault);
            _self.markers[index].addEventListener('click',function(){
                console.log(index,elSlider);
                _self.slide(index,elSlider);
            })
            latLngs.push(_self.markers[index].getLatLng());

            if(!_self.last){ _self.changeCurrent(index); }
        });

        var counter = 0;
        $.each(_self.markers,function(i){
            this.on('click',function() {
                console.log(_self.markers);
                console.log('click du marker '+counter);
                // console.log();

                var currentslider = _self.cards[counter].closest('.relation');//récupérer le bon slider
                $(currentslider).slick('slickGoTo', counter);

            });
            counter++;
        });


        var markerBounds = L.latLngBounds(latLngs);
        _self.map.fitBounds(markerBounds);


        $(this.sliderScrollSelector,elSlider).on('scroll',function(e){
            _self.handleScroll(this,callback);
        });

    };

    this.handleScroll = function(el,callback){
        var _self = this;
        var sl = $(el).scrollLeft();
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function(){
            var current = _self.checkCurrent(el);
            _self.changeCurrent(current);

            if(callback){
                callback(current);
            }
        },200);
    };

    this.checkCurrent = function(el){
        var curr = null;
        $(this.cardsSelector,el).each(function(){
            var o = $(this).offset();
            $(this).removeClass('hover');

            if(o.left > -100 && o.left < 160){
                curr = this;
            }
        });

        if(curr){
            $(curr).addClass('hover');
            return curr.getAttribute('data-'+this.indexDataName);
        }

        return false;
    };

    this.changeCurrent = function(i){
        if(i != this.last){
            if(this.last){
                this.markers[this.last].setIcon(th_maps.markersIcons[this.markerDefault]);
            }

            if(this.markers[i]){
                this.last = i;

                var zoom = this.map.getZoom();
                if(this.markers[i].__parent){
                    if(this.markers[i].__parent._zoom){
                        zoom = this.markers[i].__parent._zoom+1
                    }
                }
                this.markers[i].setIcon(th_maps.markersIcons[this.markerCurrent]);
                if(zoom > 16){
                    zoom = 16;
                }
                this.map.flyTo(this.markers[i].getLatLng(),zoom);

            }
        }
    };

    this.slide = function(i,el) {
        var o = $(this.cardsSelector + '[data-'+ this.indexDataName + '="'+i+'"]', el).offset();
        var cardWidth = $(this.cardsSelector + '[data-'+ this.indexDataName + '="'+i+'"]', el).outerWidth();

        // scroll de la gauche + offset left de la card - la moitier de l'écran moins la card pour la centrer
        $(this.sliderScrollSelector,el).scrollLeft($(this.sliderScrollSelector,el).scrollLeft() + o.left - ((document.body.clientWidth - cardWidth) / 2) );

    };

}