// SCRIPT DETECTANT CHAQUE SECTION QUAND UN LIEN HREF ARRIVE A SON ID. ADAPTER LES SELECTEURS EN FONCTION DES PROJETS
var customScrollDetect = {

    currentActive: null,
    links: [],

    init: function () {
        customScrollDetect.updateLinkPos();
        $(document).on("scroll", customScrollDetect.scrollHandle);
        customScrollDetect.scrollHandle();
    },

    updateLinkPos: function () {

        customScrollDetect.links = [];

        var height = document.body.clientHeight;

        $('.wrapper-nav a').each(function () {
            var targetTop = $(this.getAttribute("href")).offsetTop() - height / $(this).attr('data-addtop');

            customScrollDetect.links.push({
                top: targetTop,
                menuEl: this
            });
        });
    },

    active: function (current) {
        customScrollDetect.currentActive = current;

        var addClass = 'th-active';
        if (current == -1) {
            current = 0;
            addClass += ' th-active-before';
        }


        $('.wrapper-nav li').removeClass("th-active th-active-before");
        $(customScrollDetect.links[current].menuEl).parent().addClass(addClass);
    },

    scrollHandle: function () {
        var st = $(document).scrollTop();
        var current = customScrollDetect.checkCurrent(st);
        if (current !== customScrollDetect.currentActive) {
            customScrollDetect.active(current);
        }
    },

    checkCurrent: function (st) {
        var current = -1;
        var tableLenght = customScrollDetect.links.length;
        for (var i = 0; i < tableLenght; i++) {
            if (st >= customScrollDetect.links[i].top) {
                current = i;
            }
        }
        return current;
    }

};
 

// customScrollDetect.init();