var numeroVert = document.querySelector(".numero-vert");

if (numeroVert) {
    var btnToggle = document.querySelector(".numero-vert-btn");
    var popUpContainer = document.querySelector(".numero-vert-popup");
    var btnClose = popUpContainer.querySelector(".btn-close");

    btnToggle.addEventListener("click", function() {
        if (popUpContainer.classList.contains("active")) {
            popUpContainer.classList.remove("active");
        } else {
            popUpContainer.classList.add("active");
        }
    });

    btnClose.addEventListener("click", function() {
        if (popUpContainer.classList.contains("active")) {
            popUpContainer.classList.remove("active");
        }
    })
}