$('.onglet').on('click', function(){
    var context = $(this).closest('.bloc-onglet');
    var clicked = $(this).attr('data-onglet');

    console.log(context);
    console.log(context.find('>.contents>[data-onglet]'));

    context.find('>.onglets [data-onglet]').removeClass('active');
    context.find('>.contents>[data-onglet]').removeClass('active');

    // $('[data-onglet]').removeClass('active');
    // $('[data-onglet]').removeClass('active');
    context.find('[data-onglet="'+clicked+'"]').addClass('active');
});


$('.bloc-onglet').each(function(){
    var actif = false;
    var onglet_actif = "";


    $('.onglet', $(this)).each(function(){
        if('#'+$(this).attr('id') == document.location.hash ){
            actif = true;
            onglet_actif = $(this).attr('id');
        }
    });

    if(actif){
        console.log('onglet actif');
        $('.onglet', $(this)).removeClass('active');
        $('.content', $(this)).removeClass('active');
        $('.onglet#'+onglet_actif, $(this)).addClass('active');
        $('.content[data-id="'+onglet_actif+'"]', $(this)).addClass('active');

        $('.onglets>div', $(this)).animate({
            scrollLeft: $("#"+onglet_actif).offset().left
        }, 2000);
    }else{
        $('.onglet', $(this)).removeClass('active');
        $('.content', $(this)).removeClass('active');
        $('.onglet', $(this)).first().addClass('active');
        $('.content', $(this)).first().addClass('active');
    }

});