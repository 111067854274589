// Slider Basic
// ==========================================================================
$('.slick-basic-slider').each(function(){
    $(this).slick({
        infinite: true,
        dots: true,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000
    });
});

$('.slick-slider-manuel').each(function(){
    $(this).slick({
        infinite: true,
        arrows: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
        ],
    });
});

$('.slick-home-slider').each(function(){
    $(this).slick({
        infinite: true,
        dots: true,
        arrows: true,
    });
});

$('.slick-relation').each(function(){
    $(this).slick({
        infinite: true,
        dots: false,
        arrows: true,
        autoplay:true,
        autoplaySpeed:2000,
        variableWidth: true
    });

    setTimeout(function () {
        $('.slick-cloned').each(function () {
            var srcVal = $(this).find('.fit-cover img').attr('data-src');
            $(this).find('.fit-cover img').attr('src', srcVal);
        });
    }, 3000);

});
$('.slick-relation-finite').each(function(){
    $(this).slick({
        infinite: false,
        dots: false,
        arrows: true,
        autoplay:false,
        variableWidth: true
    });

    // setTimeout(function(){
    //     $('.slick-cloned').each(function(){
    //         var srcVal = $(this).find('.fit-cover img').attr('data-src');
    //         $(this).find('.fit-cover img').attr('src', srcVal);
    //     });
    // }, 3000);

});
$('.slick-relation-map').each(function(){
    $(this).slick({
        infinite: false,
        dots: false,
        arrows: true,
        autoplay:false,
        variableWidth: true
    });

    setTimeout(function(){
        $('.slick-cloned').each(function(){
            var srcVal = $(this).find('.fit-cover img').attr('data-src');
            $(this).find('.fit-cover img').attr('src', srcVal);
        });
    }, 3000);

    // $(this).on('afterChange', function(){
    //     console.log($('.slick-active',$(this)).attr('data-slick-index'));
    // });

});

$(document).ready(function () {
    $('.slick-gallery-sit').each(function () {
        $(this).slick({
            lazyLoad: 'ondemand',
            infinite: false,
            dots: false,
            arrows: true,
            slidesToScroll: 1,
            variableWidth: true,
            centerMode: false
        });
    });

    $('.slick-a-voir-sit').each(function () {
        $(this).slick({
            lazyLoad: 'ondemand',
            infinite: false,
            dots: false,
            arrows: true,
            slidesToScroll: 1,
            variableWidth: true,
            centerMode: false
        });
    });
});


// Slider Center
// ==========================================================================
$('.slick-center').each(function () {
    $(this).slick({
        dots: false,
        arrows: true,
        autoplay: true,
        loop: true,
        infinite: true,
        autoplaySpeed: 3000,
        variableWidth: true,
        centerMode: true,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    centerMode: false,
                    variableWidth: false
                }
            }
        ]
    });
});


// Slider Cards
// ==========================================================================

$('.slick-cards').each(function () {
    $(this).slick({
        infinite: false,
        dots: false,
        arrows: true,
        slidesToScroll: 1,
        variableWidth: true,
        centerMode: false
    });
});


var scrollersLazy = {};
var scrollerMap = {};


$('.wrapper-nav .sub-menu').each(function(){
    if (!isTouchDevice()) {
        $(this).slick({
            infinite: false,
            dots: false,
            arrows: true,
            variableWidth: true
        });
    }

});


function sliderRange(firstResize){

    $('[data-iscroll]').each(function() {

        var $slider = $(this);
        var $sliderItems = $('>div', this);
        var $items = $('>div>*:not(.iScrollIndicator):not([type="application/ld+json"])', this);
        var itemWidth = Math.floor($items.first().outerWidth(true));

        var isIscroll = false;
        var countItems = $items.length;
        var contentWidth = countItems * (itemWidth);


        if($(this).hasClass('onglets')){
            contentWidth = 0;
            $(this).find('>*>*').each(function(){
                var w = Math.floor($(this).outerWidth(true));
                contentWidth = contentWidth+w;
            });
        }

        if($(this).hasClass('relation-maped')){
            var padding = parseInt($(this).css("padding-left"));
            console.log(padding);
            contentWidth = contentWidth + padding;
        }


        $sliderItems.width(contentWidth);


        var lazyscroll = $(this).attr('data-lazyscroll');
        var map = $(this).attr('data-map');
        var id_scroll = $(this).attr('data-iscroll');


        if (document.body.clientWidth > 1024) {

            var config_iscroll = {
                scrollX: true,
                scrollY: false,
                momentum: true,
                snap: false,
                scrollbars: 'custom',
                interactiveScrollbars: true,
                resizeScrollbars: false,

                // fadeScrollbars: true,
                // shrinkScrollbars: 'scale',
                click: true,
                tap: true,
                // disablePointer: false,
                // disableMouse: false,
                preventDefaultException: {tagName: /.*/}
            };

            if(lazyscroll) {
                config_iscroll.mouseWheel = false;
                config_iscroll.probeType = 3;
            }

            isIscroll = true;
            var myScroll = new IScroll($slider[0], config_iscroll);

            if(lazyscroll) {
                scrollersLazy[id_scroll] = {scroller: myScroll, loading: false};
            }

            if(map) {
                scrollerMap[id_scroll] = {scroller: myScroll};
            }

            $('a', this).on('click', function (e) {
                if (myScroll.moved) e.preventDefault();
            });


            setTimeout(function(){
                $slider.addClass('loaded iscroll');
            }, 500);
        } else {

            if(lazyscroll) {
                scrollersLazy[id_scroll] = { percent_scroll: 0, scroller: false, page: 0, loading: false};
            }

            $slider.addClass('loaded');
        }

        if (document.body.clientWidth <= 1024) {
            $slider.removeClass('loaded iscroll');
        }

    });

    if  (firstResize){
        setTimeout(function(){
            $( window ).resize();
        }, 500);
    }


}
// setTimeout(function(){
    //sliderRange(true);
// }, 500);
sliderRange(true);
window.onresize = function() {
    sliderRange(false);
}

// --------------- Gestion du slider de la homepage avec les vidéos et la temporisation
var homeslider = $('.slick-home-slider');

slide_loop();
var current_timeout;

homeslider.on('afterChange',function(event, slick, currentSlide) {
    clearTimeout(current_timeout);
    slide_loop();
});

function slide_loop() {
    var duration = $('.slick-current',homeslider).data('duration') * 1000;
    current_timeout = setTimeout(function () {
        homeslider.slick('slickNext');
    }, duration);
}

// --------------- /EOF Gestion du slider de la homepage avec les vidéos et la temporisation


$('.bloc-onglet .liens').each(function(){


    var $slider = $(this);
    var $sliderItems = $('>ul', $slider);
    var $items = $('>ul>*:not(.iScrollIndicator)', $slider);
    var itemWidth = Math.floor($items.first().outerWidth(true));



    // console.log($sliderItems);
    // console.log($items);

    var isIscroll = false;
    var countItems = $items.length / 5;
    var contentWidth = (Math.ceil(countItems) * (itemWidth));
    $sliderItems.width(contentWidth);

    // console.log(Math.ceil(countItems));
    // console.log($sliderItems);


    var lazyscroll = $(this).attr('data-lazyscroll');
    var id_scroll = $(this).attr('data-iscroll');

    // console.log('nb items : '+countItems);
    // console.log('taille item : '+itemWidth);
    // console.log('taille totale : '+contentWidth);

    if (document.body.clientWidth > 1024) {


        var config_iscroll = {
            scrollX: true,
            scrollY: false,
            momentum: true,
            snap: false,
            scrollbars: true,
            // scrollbars: 'custom',
            interactiveScrollbars: true,
            resizeScrollbars: false,

            // fadeScrollbars: true,
            // shrinkScrollbars: 'scale',
            click: true,
            tap: true,
            // disablePointer: false,
            // disableMouse: false,
            preventDefaultException: {tagName: /.*/}
        };

        if(lazyscroll) {
            config_iscroll.probeType = 3;
            config_iscroll.mouseWheel = true;
        }

        isIscroll = true;


        if(lazyscroll) {
            scrollersLazy[id_scroll] = {scroller: myScroll, loading: false};
        }

        $('li', this).on('click', function (e) {
            if (myScroll.moved) e.preventDefault();
        });


        $('.onglet').on('click', function() {
            setTimeout(function () {
                myScroll.refresh();
            }, 200);
        });


        setTimeout(function(){
            $slider.addClass('loaded iscroll');
            var myScroll = new IScroll($slider[0], config_iscroll);
        }, 500);


    } else {

        if(lazyscroll) {
            scrollersLazy[id_scroll] = { percent_scroll: 0, scroller: false, page: 0, loading: false};
        }

        $slider.addClass('loaded');


    }

    if (document.body.clientWidth <= 1024) {
        $slider.removeClass('loaded iscroll');
    }
});

