// adds mobile browser class to html tag
var ua = navigator.userAgent.toLowerCase();
function removeSpaces(ua) {
    return ua.split(' ').join('');
}
ua = removeSpaces(ua);

var iOS = ua.match(/(iphone|ipod|ipad)/);
if(iOS) { 
    $('body').addClass('ios');
}

// Lancement du script Librairie Zoombox
$('.zoombox').zoombox();

// Lancement du script de ObjectFit
objectFitImages('.fit-cover img');

// Languages selector
if (isTouchDevice()) {
    $('.lang > .sub-menu').addClass('sub-lang-mobile');
    $('.lang').addClass('is-display');
}

/* Detect the scroll of the page and animate the menu */
$(window).on('scroll', function (e) {
    var st = $(this).scrollTop();

    if (st > $( window ).height() - 60) {
        $("body").addClass("is-scrolled");
    }
    else {
        $("body").removeClass("is-scrolled");
    }
});

/* Detect the scroll of the page and animate the menu */
$(window).on('scroll', function (e) {
    var st = $(this).scrollTop();

    if (st > 60) {
        $("body").addClass("has-scrolled");
    }
    else {
        $("body").removeClass("has-scrolled");
    }
});

// Lazyload
var lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy"
});


//affiche le lien de retour uniquement si un historique existe
if(window.history.length > 1) {
    $('main .retour').show();
}


// Ouverture des overlay
// -------------------------------------------------
// -------------------------------------------------
// -------------------------------------------------d

$('[data-overlay-open="overlay-search"]').on('click', function(){
    $('.search-wrapper').addClass('search-open');
    $('.shadow-overlay').removeClass('open');
    setTimeout(function(){
        $('.search-input').focus();
    }, 500);
});
$('[data-overlay-close="overlay-search"]').on('click', function(){
    if(! $('header').hasClass('menu-open')){
        $('.search-wrapper').removeClass('search-open');
    }
});


// Footer mobile
// -------------------------------------------------
$('.title-footer').on('click', function(){

    $(this).toggleClass('open');
    $(this).find('+ul').toggleClass('open');

});



// $('[data-overlay-open="filters-overlay"]').on('click',function(){
//     document.webkitExitFullscreen();
//     document.exitFullscreen();
// });

$('.numero').on('click', function(e){

    if(!$(this).hasClass('visible')){
        e.preventDefault();

        var num = $(this).attr('data-numero');
        $(this).attr('href', 'tel:'+num);
        $(this).text(num);
        $(this).addClass('visible');
    }

});


$('.fullpage-header .icon-mousse').click(function() {
    $('html, body').animate({
        scrollTop: $(this).offset().top}, 'slow', 'swing', function() {
    });
});