$('[data-frmval]').each(function(){
    this.setAttribute('placeholder',this.getAttribute('data-frmval'));
    if(this.value === this.getAttribute('data-frmval')){
        this.value = '';
    }
});

$('form select:not(.selectize)').selectric();

$('select.selectric').selectric();



var selectize = $('.selectize').selectize();
var selectized = $('.selectize#com option[selected="selected"]').val(); //On vérifie quel item est actif

//Si l'item actif c'est la valeur de base on en l'affiche pas
console.log($('.selectize#com option[selected="selected"]'));
if(selectized == "empty"){
    var selectizeClear = selectize[0].selectize;
    selectizeClear.clear();
}


var currentLangFormidable = 'fr';
var buttonUploadText = {
	'fr':"+"
}

$('.frm_radio > label').append('<span></span>');

$('.frm_checkbox > label').append('<span></span>');
$('.checkbox').append('<span></span>');

$('.bloc-form input[type="text"], ' +
    '.bloc-form input[type="email"], ' +
    '.bloc-form input[type="tel"], ' +
    '.bloc-form input[type="number"], ' +
    '.bloc-form textarea').each(function(){
    $(this).siblings('.frm_primary_label').addClass('abs');

    //Si l'input est remplis on affiche le label en hauteur
    if( $(this).val().length != '' ) {
        $(this).siblings('.frm_primary_label').addClass('focused');
    }

    //Au focus on ajoute la classe focus pour dégager le label
    $(this).on('focusin', function(){
        $(this).siblings('.frm_primary_label').addClass('focused');
    });
    $(this).on('focusout', function(){
        console.log($(this).val());
        //On enlève la classe focused uniquement si le champ est pas remplis
        if( $(this).val().length == '' ) {
            $(this).siblings('.frm_primary_label').removeClass('focused');
        }
    });

});


// On créé des datepicker pour chaque champ date qu'on a sur la page que quand on est pas sur touch device
var datePickerCounter = 0;

if(thGlobalDefaults.currentlanguage == 'fr') {
    var i18n = {
        previousMonth: 'Mois précédent',
        nextMonth: 'Mois prochain',
        months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', "Octobre", "Novembre", "Décembre"],
        weekdays: ['dimanche', " lundi ", " mardi ", " mercredi ", " jeudi ", " vendredi ", " samedi "],
        weekdaysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
    };
}

$('input[type="date"], input.frm_date').each(function(){

    var pickers = [];

    pickers[datePickerCounter] = new Pikaday({ field: $('input.frm_date, input[type="date"]')[datePickerCounter],i18n:i18n });

    $(this).after('<span class="icon-calendrier"></span>');
    datePickerCounter ++;
});


/* Scroll to after submit form */
var $scrollToEl = $('[data-scrolltothis="true"]');
if($scrollToEl.length > 0){
    var offsetScrollTo = $scrollToEl.offset();
    $(document).scrollTop(offsetScrollTo.top-80);
}
