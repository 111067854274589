if($('.page-detail').length > 0){


    var isInitStick = false;

    // Si nous sommes sur le détail SIT ou détail Rando
    if(!$('.wrapper-sticky.wrapper-sticky-event').length > 0){

        var $elSticky = $('.wrapper-content-sit .wrapper-sticky');


        if (!isTabletPortraitOrSmalller()) {
            $elSticky.stick({top: 100});
            isInitStick=true;
        }

        $(window).on('resize', function () {
            console.log(isTabletPortraitOrSmalller());
            if (isTabletPortraitOrSmalller()) {
                $elSticky.stick('stop');
                $elSticky.attr('style','');
            } else {
                if(isInitStick){
                    $elSticky.stick('update');
                }else{
                    isInitStick=true;
                    setTimeout(function(){
                        $elSticky.stick({top: 100});
                    },500);
                }
            }
        });
    }
}

// PAGE SINGLE SIT
function callbackMapSingleSIT(macarte) {
    macarte.zoomControl.setPosition('topright');
    macarte.setZoom(14);
    if (isTouchDevice()) {
        macarte.dragging.disable();
    }
}


//Bouger le titre de place dans le
function moveTitleSingle(){
    if ($(window).width() <= 980) {
        $('[data-head-sit-sidebar]>div:not(.galerie)').appendTo('[data-head-sit-head]');
    }
    if ($(window).width() > 980) {
        $('[data-head-sit-head]>div:not(.galerie)').appendTo('[data-head-sit-sidebar]');
    }
}

if($('.sit-content').length > 0){
    moveTitleSingle();

    $(window).on('resize', function () {
        moveTitleSingle();
    });
}


// ------ MAIL chargé en ajax au clic sur 'nous contacter' -----------
$('[data-js="mailsit"]').on('click',function (eventObject) {

    if(!$(this).hasClass('loaded')) {

        eventObject.preventDefault();
        var _this = $(this);

        $.ajax({
            url: ajaxurl,
            method: 'POST',
            data: {
                action: 'th_ajaxcall',
                class: 'FrontApidae',
                method: 'ajaxGetMail',
                id_apidae: $(this).attr('data-apidaeid')
            },
            success: function (data) {
                result = JSON.parse(data);
                console.log(result);
                if (result.success) {
                    _this.attr('href', 'mailto:' + result.data[0]);
                    _this.text(result.data[0]);
                    _this.addClass('loaded');
                } else {
                    console.log(result.data);
                }
            },
        });
    }

})